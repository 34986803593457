import { io } from "socket.io-client";

const URL = process.env.NODE_ENV == 'development' 
		? window.location.origin.replace('8080', '3000') 
		: "https://socket.sistemi.globe.com.al"

const socket = io(URL, { 
	query: {
    token: localStorage.getItem('token')
  },
	transports: ['websocket'], 
	autoConnect: false 
});

export default socket;
